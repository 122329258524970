import React, { Component } from "react";
import { Link } from "gatsby";
import SignUpFormBase from "./molecules/SignUpFormBase";
import SignInGoogle from "../../molecules/SignInGoogle";
import SignInFacebook from "../../molecules/SignInFacebook";
import SignInTwitter from "../../molecules/SignInTwitter";
import SignUpLink from "../../scenes/SignUp/atoms/SignUpLink";
import SignInGithub from "../../molecules/SignInGithub";
import { CONTACT, LANDING } from "../../../../../constants/routes";
import Button from "../../../../atoms/Button";

const SignUp = ({ uid, email }) => {
  console.log("[SignUp.jsx]", uid, email);
  return (
    <div className="register">
      <div className="register__image" />
      <div className="register__content">
        <div className="register__content__inner">
          <div className="register__content__header">
            <Link className="removeLinkDecoration" to={LANDING}>
              <img
                className="register__content__header__logo"
                src={require("../../../../../assets/LogoWithName.jpg")}
                alt="logo"
              />
            </Link>
            <div className="register__content__header__title">
              Create Your Account
            </div>
          </div>
          <div className="register__content__fields">
            {!!uid && !!email && (
              <div className="register__content__form">
                <SignUpFormBase uid={uid} email={email} />
              </div>
            )}
            {(!uid || !email) && (
              <div className="register__content__form">
                <p className="signupError">
                  Sorry, only those who are invited can create a Share Bibles
                  account. If you have an invitation email, please click on the
                  link in that email.
                </p>
                <p className="signupError">
                  Please <Link to={CONTACT}>contact us</Link> for more
                  information.
                </p>
              </div>
            )}
            <Link className="removeLinkDecoration" to={LANDING}>
              <Button text="Go to Home Page" />
            </Link>
            {/* <div className="register__content__or">
              <span className="register__content__or__line" />
              <span className="register__content__or__text">or</span>
              <span className="register__content__or__line" />
            </div>
            <div className="register__content__providers">
              <div className="register__content__providers--left">
                <SignInGoogle />
                <SignInGithub />
              </div>
              <div className="register__content__providers--right">
                <SignInTwitter />
                <SignInFacebook />
              </div>
            </div> */}
          </div>
          {/* <div className="register__content__register">
            <SignUpLink />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
