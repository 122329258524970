import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Colors from "../../constants/Colors";

export default function FormDialog({ open, setOpen, children, title }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: 15,
          margin: 0,
          backgroundColor: Colors.primary,
          color: "white",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>{title}</div>
        <div style={{ flex: 0 }} onClick={() => setOpen(false)}>
          X
        </div>
      </div>
      {/* {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>} */}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
