import React from "react";
import Layout from "../utils/layout";
import SignUp from "../components/scenes/Auth/scenes/SignUp/SignUp";
import { navigate } from "gatsby";
import { LANDING } from "../constants/routes";

export default ({ location }) => {
  const hasUID = !!location && !!location.hash && location.hash.length === 29;
  let uid = null;
  let email = null;
  if (location && location.hash) {
    const hashSplit = location.hash.split("---");
    if (hashSplit.length !== 2 || hashSplit[0].length !== 29) {
      return;
    }
    uid = hashSplit[0].substring(1, 29);
    email = hashSplit[1];
  }

  return (
    <Layout hideNav>
      <SignUp uid={uid} email={email} />
    </Layout>
  );
};

// 644IkUqkWJfih5obyqBYfN97bdup---rob.wiebe@uofn.edu
