export default {
  primary: "#1F79BC",
  secondary: "#199DBE",
  grey: "#B6B6B6",
  darkgrey: "#565656",
  statusColors: {
    accepted: "#00b100",
    delivered: "#009d00",
    have: "#008900",
    unfinished: "#ffea00",
    need: "#0080ff",
    rejected: "#ff3b3b",
  },
  statusColorsLight: {
    accepted: "#33E433",
    delivered: "#33D033",
    have: "#33BC33",
    unfinished: "#FFFF33",
    need: "#33B3FF",
    rejected: "#FF6E6E",
  },
  statusColorsDark: {
    accepted: "#007E00",
    delivered: "#006A00",
    have: "#005600",
    unfinished: "#CCB700",
    need: "#004DCC",
    rejected: "#CC0808",
  },
};
